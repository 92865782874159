<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="book-inventory mt-5">
    <h1 class="heading">Cash Detail By Produtct</h1>
    <div class="d-flex mt-4 ml-0" style="gap: 10px">
      <Autocomplete
        @setFilter="setFilter"
        :styleBorder="'out-lined'"
        placeholder="Select..."
        :keyFilter="header.value"
        :selectedInit="filters['location']"
        :listItem="header.items"
        :announLabel="header.announLabel"
      />
      <v-text-field
        type="date"
        v-model.lazy="fromDate"
        label="From date"
        class="select-item"
        outlined
        dense
        hide-details
      />
      <v-text-field
        type="date"
        v-model.lazy="toDate"
        label="To date"
        class="select-item"
        outlined
        dense
        hide-details
      />
      <v-btn color="primary" @click="SearchData">Search</v-btn>
    </div>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="filtered"
      :items-per-page="1000000"
      hide-default-header
      hide-default-footer
      class="elevation-0 mb-5 bookTable"
      fixed-header
      height="79vh"
      id="bookTable"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :style="{
                background: '#0b2585',
                color: '#fff',
                textAlign: 'center',
                width: `${header.width}`,
                maxWidth: `${header.width}`,
              }"
              v-html="header.text"
            ></th>
          </tr>
        </thead>
        <tr class="fixed-book-filter">
          <th v-for="header in headers" :key="header.text">
            <AutoFilter
              v-if="autoFilters.hasOwnProperty(header.value)"
              @setFilter="setAutoFilter"
              :styleBorder="'border-bottom'"
              :keyFilter="header.value"
              :selectedInit="autoFilters[header.value]"
              :listItem="groupColumnValueList(header.value)"
            />
          </th>
        </tr>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length === 0" style="text-align: center">
          <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
            No data available
          </div>
        </tbody>
        <tbody v-for="(item, index) in filteredTotal" :key="index">
          <tr class="row-overall">
            <td colspan="9" style="text-align: left !important">
              <v-icon class="mr-5" @click="changeHiddenSup(item.locationID)">{{
                listHidden.includes(item.locationID) ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
              <p>Center:</p>
              {{ item.location }}
            </td>
            <td>
              <p>
                {{ item.overallEndQty.toLocaleString() }}
              </p>
            </td>
            <td>-</td>
            <td>
              <p>
                {{ item.overallAmount.toLocaleString() }}
              </p>
            </td>
          </tr>
          <tr class="row-sale" v-for="(item2, index) in item.purchaseOrd" :key="index">
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ index + 1 }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.paymentID }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ _dayjs(item2.paymentDate).format('DD/MM/YYYY') }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.studentCode }}
            </td>
            <td style="width: 250px" v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.studentName }}
            </td>
            <td style="width: 200px" v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.classCode ? item2.classCode : '-' }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.productCode }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.description }}
            </td>
            <td
              :style="{ textAlign: item2.remarks ? 'justify' : 'center', width: '350px' }"
              v-if="listHidden.includes(item2.locationID) === true"
            >
              {{ item2.remarks ? item2.remarks : '-' }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.qty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.unitPrice.toLocaleString() }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.paidByProduct.toLocaleString() }}
            </td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr class="bookTableFoot">
            <td class="text-h6" colspan="9" style="text-align: left !important">Grand Total</td>
            <td>
              {{ filteredPayment.overallEndQty.toLocaleString() }}
            </td>
            <td>-</td>
            <td>
              {{ filteredPayment.overallAmount.toLocaleString() }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { book_management } from '@/api/book_management'
// import { requestOptions } from '@/api/request-options'
import Autocomplete from '@/components/Utils/AutocompleteAnnoncement.vue'
import AutoFilter from '@/components/Utils/Autocomplete.vue'

export default {
  components: {
    Autocomplete,
    AutoFilter,
  },
  setup() {
    return {}
  },
  data() {
    return {
      isLoading: false,
      header: {},
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      fromDate: 0,
      year: 0,
      filters: {
        location: [],
      },
      listLocation: [],
      listYear: [],
      listMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      listInventory: [],

      //
      autoFilters: {
        paymentID: [],
        paymentDate: [],
        paidByProduct: [],
        unitPrice: [],
        qty: [],
        remarks: [],
        description: [],
        productCode: [],
        classCode: [],
        studentName: [],
        studentCode: [],
      },
      //setting table
      headers: [
        { text: 'No', align: 'center', value: 'no', width: '50px', sortable: false },
        {
          text: 'Payment ID',
          value: 'paymentID',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Payment Date',
          value: 'paymentDate',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          align: 'center',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Student Name',
          value: 'studentName',
          align: 'center',
          width: '210px',
          sortable: false,
        },
        {
          text: 'Class Code',
          value: 'classCode',
          align: 'center',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Product Code',
          value: 'productCode',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          width: '400px',
          sortable: false,
        },
        { text: 'Remark', value: 'remarks', align: 'center', width: '200px', sortable: false },
        { text: 'Quantity', value: 'qty', align: 'center', width: '100px', sortable: false },
        {
          text: 'Unit Price',
          value: 'unitPrice',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Amount',
          value: 'paidByProduct',
          width: '100px',
          align: 'center',
          sortable: false,
        },
      ],
      listHidden: [],
    }
  },
  computed: {
    filteredTotal() {
      return this.filtered.map(el => {
        return {
          ...el,
          overallEndQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.qty, 0),
          overallAmount: el.purchaseOrd.reduce((acc, cur) => acc + cur.paidByProduct, 0),
        }
      })
    },
    filteredPayment() {
      let grandTotal = {
        overallEndQty: 0,
        overallAmount: 0,
      }
      this.filtered.forEach(el =>
        el.purchaseOrd.forEach(d => {
          grandTotal.overallEndQty += d.qty
          grandTotal.overallAmount += d.paidByProduct
        })
      )
      grandTotal.overallEndQty = Number(grandTotal.overallEndQty.toFixed(0))
      grandTotal.overallAmount = Number(grandTotal.overallAmount.toFixed(0))
      return grandTotal
    },
    filtered() {
      return this.listInventory.map(el => {
        return {
          ...el,
          purchaseOrd: el.purchaseOrd.filter(d => {
            return Object.keys(this.autoFilters).every(f => {
              return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
            })
          }),
        }
      })
    },
  },
  created() {

    this.getCenter()
    this.getFromToDate()
    this.init()
  },
  methods: {
    SearchData() {
      this.init()
    },
    changeHiddenSup(subItem) {
      if (this.listHidden.includes(subItem)) {
        this.listHidden = this.listHidden.filter(function (item) {
          return item !== subItem
        })
      } else {
        this.listHidden = [...this.listHidden, subItem]
      }
    },
    /**
     * ? config filter
     */
    setAutoFilter(objectFilterChange) {
      this.autoFilters = {
        ...this.autoFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      let columnVal = []
      this.listInventory.forEach(el => {
        columnVal = [...columnVal, ...el.purchaseOrd]
      })
      return [...new Set(columnVal.map(d => d[val]))].filter(y => y)
    },
    /**
     * ? config center autocomplete
     */
    getCenter() {
      this.listLocation = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
      const location = this.getIdArray(
        this.listLocation,
        [parseInt(localStorage.getItem('currentCenter'))],
        'locationID',
        'location'
      )
      this.filters.location = location
      this.header = {
        announLabel: 'Center',
        value: 'location',
        items: this.listLocation.map(el => el.location),
      }
    },
    getIdArray(arr1, arr2, name, id) {
      return arr1.filter(item => arr2.includes(item[name])).map(item => item[id])
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },

    /**
     * ? Configure set month and Year
     */
    getFromToDate() {
      this.fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 2)
        .toISOString()
        .split('T')[0]
      this.toDate = this.getLastDayOfMonth()
    },
    getLastDayOfMonth() {
      const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
      const formattedLastDay = `${new Date().getFullYear()}-${String(
        new Date().getMonth() + 1
      ).padStart(2, '0')}-${String(lastDay).padStart(2, '0')}`
      return formattedLastDay
    },
    async init() {
      this.isLoading = true
      let emptyArr = []
      const locationArr = this.getIdArray(
        this.listLocation,
        this.filters.location,
        'location',
        'locationID'
      )
      if (locationArr.length > 0) {
        const promises = locationArr.map(async el => {
          const res = await book_management.getCashDetail(el, this.fromDate, this.toDate)
          if (res.length > 0) {
            const mergedInfo = []
            res.forEach(element => {
              const { location, locationID } = element
              const existingElement = mergedInfo.find(el => el.locationID === locationID)
              delete element.location
              if (existingElement) {
                existingElement.purchaseOrd.push(element)
              } else {
                const newElement = {
                  location,
                  locationID,
                  purchaseOrd: [element],
                }
                mergedInfo.push(newElement)
              }
            })
            emptyArr = [...emptyArr, mergedInfo[0]]
            emptyArr.sort((a, b) => a.locationID - b.locationID)
          }
        })
        await Promise.all(promises)
        this.listInventory = emptyArr
        this.isLoading = false
      }
    },

    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 48px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
.row-sale {
  background: #f3f3f3;
  td {
    text-align: center;
  }
}
.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
